/* Resets */
/* @import-normalize; */ /* Puts dumb margins on headings */

*, html, body, ul, ol, li, img, p, a {
  /* @include no-tap; */
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  letter-spacing: 0;
  line-height: 1;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

/* a, a:link, a:visited, a:active, input, button, textarea, select {
  @include animate;
  border-radius: 0;
} */

/* button, textarea, select {
  @include browser-prefix(appearance, none);
} */

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner, select::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
  width: 0;
}

a > svg {
  pointer-events: none;
}

/* svg {
  fill: inherit;

  &, use {
    @include animate;
  }
} */

::selection, ::-moz-selection {
  background: #F5EBD0;
  color: #000000;
}

/* Fonts */
@font-face {
  font-family: 'Tan-Mon-Cheri';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/tan-mon-cheri.woff2') format('woff2'),
       url('assets/fonts/tan-mon-cheri.woff') format('woff'),
       url('assets/fonts/tan-mon-cheri.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas-Neue';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/bebasneue.woff2') format('woff2'),
       url('assets/fonts/bebasneue.woff') format('woff'),
       url('assets/fonts/bebasneue.ttf') format('truetype');
}

@font-face {
  font-family: 'Carlito';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/carlito.woff2') format('woff2'),
       url('assets/fonts/carlito.woff') format('woff'),
       url('assets/fonts/carlito.ttf') format('truetype');
}

@font-face {
  font-family: 'Carlito-B';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/carlito-bold.woff2') format('woff2'),
       url('assets/fonts/carlito-bold.woff') format('woff'),
       url('assets/fonts/carlito-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Carlito-BI';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/carlito-bolditalic.woff2') format('woff2'),
       url('assets/fonts/carlito-bolditalic.woff') format('woff'),
       url('assets/fonts/carlito-bolditalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Carlito-I';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/carlito-italic.woff2') format('woff2'),
       url('assets/fonts/carlito-italic.woff') format('woff'),
       url('assets/fonts/carlito-italic.ttf') format('truetype');
}

/* General */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* -webkit-background: linear-gradient(#D4D8E2, #F1E7DB);
  -moz-background: linear-gradient(#D4D8E2, #F1E7DB);
  -ms-background: linear-gradient(#D4D8E2, #F1E7DB);
  -o-background: linear-gradient(#D4D8E2, #F1E7DB);
  background: linear-gradient(#D4D8E2, #F1E7DB);
  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -ms-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed; */
  background-image: url('assets/images/dyd-background-v.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  font-family: 'Carlito', 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 4em 5%;
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
}

h1, h2, h3 {
  display: inline-block;
  line-height: 1.7em;
  position: relative;
  width: 100%;
}

/* h1 {
  font-family: 'Tan-Mon-Cheri';
  font-size: 2.456em;
}

h2 {
  font-family: 'Bebas-Neue';
  font-size: 2.438em;
  line-height: 1;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 0.875em;
} */

p {
  display: inline-block;
  line-height: 1.4;
  margin-bottom: 0.5em;
  position: relative;
  width: 100%;
}

section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 4em 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
